import React from 'react'


const Form10IA = () =>{


    return (
        <div style={{display:"flex",justifyContent:"center",alignItems : "center"}}>
            <p style={{fontSize:"14px"}}><b>FORM NO. 10-IA </b> <br></br> [See Sub-rule(2) of 11A]</p>
        </div>
     )
}
export default Form10IA